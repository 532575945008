import { fromJS } from 'immutable'
import { handleActions } from 'redux-actions'

import {
  UPDATE_POLICY,
  UPDATE_POLICY_SUCCESS,
  DELETE_POLICY,
  DELETE_POLICY_SUCCESS,
} from 'containers/ControlFrameworkData/constants'

import { SET_POLICY } from './constants'

const initialState = fromJS({
  policy: {},
  saving: false,
  deleting: false,
})

const policyEditReducer = handleActions(
  {
    [DELETE_POLICY]: state => state.set('deleting', true),
    [DELETE_POLICY_SUCCESS]: state => state.set('deleting', false),
    [SET_POLICY]: (state, { payload }) => state.set('policy', fromJS(payload)),
    [UPDATE_POLICY]: state => state.set('saving', true),
    [UPDATE_POLICY_SUCCESS]: (state, { payload }) =>
      state.set('saving', false).set('policy', fromJS(payload)),
  },
  initialState
)

export default policyEditReducer
