import { all, put, takeLatest } from 'redux-saga/effects'
import gql from 'graphql-tag'
import { FETCH_POLICY_QUERY } from 'static-queries'

import { getClient } from 'apolloClient'
import { setPolicy } from './actions'
import { FETCH_POLICY } from './constants'

function* getPolicy(action) {
  try {
    const client = getClient()
    const result = yield client.query({
      forceFetch: true,
      fetchPolicy: 'network-only',
      query: gql`
        ${FETCH_POLICY_QUERY}
      `,
      variables: {
        srn: action.payload,
      },
    })

    if (result.data.ControlPolicies.items === null) {
      throw new Error('Bad formatting of response from server: items is null')
    }

    if (result.data.ControlPolicies.count > 0) {
      const policy = result.data.ControlPolicies.items[0]
      if (typeof policy.evalCriteria === 'string') {
        policy.evalCriteria = JSON.parse(policy.evalCriteria)
      }

      yield put(setPolicy(policy))
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error fetching policy', e)
  }
}

function* policyEditSaga() {
  yield all([takeLatest(FETCH_POLICY, getPolicy)])
}

export default policyEditSaga
