import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List, Map } from 'immutable'
import qs from 'query-string'
import { Badge } from 'reactstrap'
import { Link } from 'react-router-dom'
import { getNameForHelmet } from 'utils/sonraiUtils'
import { Helmet } from 'react-helmet'
import BorderedCard from 'components/BorderedCard'
import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import LoadingAnim from 'components/LoadingAnim'
import { selectControlGroups } from 'containers/ControlFrameworkData/selectors'
import _ from 'lodash'
import { deletePolicy } from 'containers/ControlFrameworkData/actions'
import permissionChecker from 'containers/PermissionChecker'
import MarkdownDisplay from 'components/MarkdownDisplay'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import Icon from 'components/Icon'
import DropdownActions, {
  DropdownAnchor,
  DropdownMenu,
  DropdownItem,
} from 'components/DropdownActions'
import Breadcrumb, { BreadcrumbItem } from 'components/Breadcrumb'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import SectionHeader from 'components/SectionHeader'
import CodeBlock from 'components/CodeBlock'
import { goBack } from 'connected-react-router'

import { fetchPolicy, setPolicy } from './actions'
import {
  selectPolicy,
  selectPolicyId,
  selectDeleting,
  selectUserFrameworkIds,
  selectSaving,
} from './selectors'
import reducer from './reducer'
import sagas from './sagas'
import EditPolicyModal from './EditPolicyModal'
import messages from './messages'
import CreatedByBadge from 'components/CreatedByBadge'
import SavedSearchDisplay from 'components/SavedSearchDisplay'
import TextLink from 'components/TextLink'
export class PolicyEdit extends React.Component {
  constructor(props) {
    super(props)

    props.fetchPolicy(props.policyId)

    this.styles = {
      navContextContainer: {
        height: '24px',
        gridArea: 'breadcrumbs',
      },
      container: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'auto 45px 1fr',
        gridTemplateAreas: '"breadcrumbs" "title" "policies"',
        gridColumnGap: '1em',
        gridRowGap: '0.5em',
        padding: '1em',
        height: '100%',
        overflow: 'auto',
      },
      policyTitle: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        gridColumnGap: '1em',
      },
      detailsEntry: {
        paddingBottom: '0.5em',
        fontSize: '0.9em',
      },
      label: {
        fontWeight: 400,
        display: 'block',
        color: props.theme.neutralDark,
      },
      menuAnchor: {
        border: 'none',
        margin: '-1em',
      },
      dropdownMenu: {
        padding: '0.7em',
        borderRadius: '3px',
      },
      dropdownMenuItem: {
        display: 'block',
        padding: '0.5em 0',
        width: '100%',
        textAlign: 'left',
      },
      lastTriggerInfo: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      titleText: {
        fontSize: '1.3em',
      },
      searchInfo: {
        borderBottom: `1px solid ${props.theme.neutralLight}`,
        marginBottom: '1em',
        paddingBottom: '0.5em',
      },
      controlGroupBadge: {
        backgroundColor: props.theme.neutralLight,
        color: props.theme.neutralMedium,
        marginRight: '0.3em',
        cursor: 'pointer',
      },
    }

    this.state = {
      editingPolicy: false,
    }
  }

  componentDidUpdate(oldProps) {
    if (oldProps.policyId !== this.props.policyId) {
      this.props.setPolicy({})
      this.props.fetchPolicy(this.props.policyId)
    }
    if (oldProps.deleting && !this.props.deleting) {
      this.props.goBack()
    }
  }

  componentWillUnmount() {
    this.props.setPolicy({})
  }

  handleDeletePolicy = () => {
    this.props.deletePolicy(this.props.policy.get('srn'))
  }

  toggleEditingPolicy = () => {
    this.setState(currentState => ({
      editingPolicy: !currentState.editingPolicy,
    }))
  }

  getSearchNavParams = () => {
    const search = this.props.policy.getIn(['contains', 'items', 0])

    if (search.get('__typename') === 'Savedquery') {
      return {
        pathname: '/App/GraphExplorer',
        state: {
          searchName: search.get('name'),
        },
      }
    } else {
      return {
        pathname: '/App/Search',
        search: qs.stringify({
          searchId: search.get('sid'),
        }),
      }
    }
  }

  render() {
    if (this.props.policy.isEmpty()) {
      return <LoadingAnim />
    }

    const canEdit = this.props.userHasPermission({
      permissionName: 'edit.controlframeworks',
      resourceId: this.props.policy.get('resourceId'),
    })

    const canDelete = this.props.userHasPermission({
      permissionName: 'edit.controlframeworks',
      resourceId: this.props.policy.get('resourceId'),
    })

    const search = this.props.policy.getIn(['contains', 'items', 0])

    const evalCriteria = this.props.policy.get('evalCriteria') || Map()
    const firstCriteriaKey =
      evalCriteria.size > 0 ? evalCriteria.keySeq().first() : null

    const policyEvalType = this.props.policy.getIn([
      'evalCriteria',
      firstCriteriaKey,
      0,
    ])
    const policyEvalTarget = this.props.policy.getIn([
      'evalCriteria',
      firstCriteriaKey,
      1,
    ])
    const remediationType = this.props.policy.get('remediationType')
    const policyEvalOp = firstCriteriaKey

    const alertingLevel = this.props.policy.get('alertingLevelNumeric') || '-'
    const name = getNameForHelmet(this.props.policy.toJS())

    return (
      <Fragment>
        {this.state.editingPolicy && (
          <EditPolicyModal
            policy={this.props.policy}
            toggle={this.toggleEditingPolicy}
          />
        )}
        <div style={this.styles.container}>
          <div style={this.styles.navContextContainer}>
            <Breadcrumb>
              <BreadcrumbItem>
                <TextLink
                  color="primary"
                  to={{ pathname: '/App/ControlCenter' }}
                >
                  All Control Frameworks
                </TextLink>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <TextLink
                  color="primary"
                  to={{ pathname: '/App/ControlCenter/ListPolicy' }}
                >
                  All Policies
                </TextLink>
              </BreadcrumbItem>
              <BreadcrumbItem>Policy Details</BreadcrumbItem>
            </Breadcrumb>
          </div>
          <div style={this.styles.titleContainer}>
            <div style={this.styles.policyTitle}>
              <div style={this.styles.titleText}>
                {this.props.policy.get('title')}
              </div>

              <div>
                <DropdownActions toggle={this.toggleDropdown}>
                  <DropdownAnchor
                    borderless
                    style={{ padding: '0 0.3em', fontSize: '1.2em' }}
                  >
                    {this.props.saving || this.props.deleting ? (
                      <Icon transform="grow-2" spin fa name="sync" />
                    ) : (
                      <Icon transform="grow-2" fa name="cog-solid" />
                    )}
                  </DropdownAnchor>

                  <DropdownMenu>
                    <DropdownItem
                      onClick={this.toggleEditingPolicy}
                      disabled={!canEdit || this.props.deleting}
                    >
                      Edit
                    </DropdownItem>
                    <DropdownItem
                      onClick={this.handleDeletePolicy}
                      disabled={!canDelete || this.props.deleting}
                    >
                      {this.props.deleting ? (
                        <span>
                          <Icon fa spin name="sync" /> Deleting...
                        </span>
                      ) : (
                        'Delete'
                      )}
                    </DropdownItem>
                  </DropdownMenu>
                </DropdownActions>
              </div>
            </div>
          </div>

          <BorderedCard>
            <div>
              {this.props.controlGroups && (
                <div>
                  {this.props.policy
                    .getIn(['containedByControlFramework', 'items'], List())
                    .filter(cf =>
                      this.props.userFrameworkIds.includes(cf.get('srn'))
                    )
                    .map(cf => {
                      const controlFramework = this.props.controlGroups.get(
                        cf.get('srn'),
                        Map()
                      )

                      return (
                        <Link
                          key={controlFramework.get('srn')}
                          to={{
                            pathname: '/App/ControlCenter/ControlGroup',
                            search: qs.stringify({
                              controlGroupId: cf.get('srn'),
                            }),
                          }}
                        >
                          <Badge style={this.styles.controlGroupBadge} pill>
                            {controlFramework.get('friendlyName') ||
                              controlFramework.get('title')}
                          </Badge>
                        </Link>
                      )
                    })}
                </div>
              )}
            </div>
            <div style={this.styles.searchInfo}>
              <div style={this.styles.lastTriggerInfo}>
                <div style={this.styles.detailsEntry}>
                  <span style={this.styles.label}>Search</span>
                  {search !== undefined ? (
                    <SavedSearchDisplay
                      search={search.toJS()}
                      to={this.getSearchNavParams()}
                    />
                  ) : (
                    <span
                      style={{ ...this.styles.searchStyle, cursor: 'default' }}
                    >
                      <Icon fa name="exclamation" style={{ color: 'red' }} /> No
                      Search attached to policy
                    </span>
                  )}
                </div>
                <div style={this.styles.detailsEntry}>
                  <span style={this.styles.label}>Trigger</span>
                  <DynamicFormattedMessage
                    defaultMessage={policyEvalType}
                    {...messages[
                      `evalType${
                        policyEvalType.charAt(0).toUpperCase() +
                        policyEvalType.slice(1)
                      }Label`
                    ]}
                  />{' '}
                  <DynamicFormattedMessage
                    defaultMessage={policyEvalOp}
                    {...messages[`evalOp${policyEvalOp.toUpperCase()}Label`]}
                  />{' '}
                  {policyEvalTarget}
                </div>
                <div style={this.styles.detailsEntry}>
                  <span style={this.styles.label}>Remediation Type</span>

                  {remediationType
                    ? _.startCase(_.toLower(remediationType))
                    : '-'}
                </div>
                <div style={this.styles.detailsEntry}>
                  <span style={this.styles.label}>Alert Level</span>
                  {alertingLevel}
                </div>
                <div style={this.styles.detailsEntry}>
                  <span style={this.styles.label}>Created By</span>
                  <CreatedByBadge
                    createdBy={this.props.policy.get('createdBy')}
                  />
                </div>
              </div>
            </div>
            {this.props.policy.get('description') && (
              <Fragment>
                <SectionHeader small>Description</SectionHeader>
                <MarkdownDisplay
                  content={this.props.policy.get('description')}
                />
              </Fragment>
            )}

            {this.props.policy.get('hasManualRemediations') &&
              !this.props.policy
                .getIn(['hasManualRemediations', 'items'], List())
                .isEmpty() && (
                <Fragment>
                  <SectionHeader small style={{ marginTop: '2em' }}>
                    Manual Remediations
                  </SectionHeader>
                  {this.props.policy
                    .getIn(['hasManualRemediations', 'items'], List())
                    .map((remediation, index) => (
                      <CodeBlock key={index}>
                        {remediation.get('remediationSteps')}
                      </CodeBlock>
                    ))}
                </Fragment>
              )}
          </BorderedCard>
        </div>
        <Helmet title={`Sonrai - ${name}`} />
      </Fragment>
    )
  }
}

PolicyEdit.propTypes = {
  canDelete: PropTypes.func,
  deleting: PropTypes.bool,
  saving: PropTypes.bool,
  deletePolicy: PropTypes.func,
  policy: ImmutablePropTypes.map.isRequired,
  policyId: PropTypes.string,
  controlGroups: ImmutablePropTypes.map.isRequired,
  fetchPolicy: PropTypes.func.isRequired,
  setPolicy: PropTypes.func.isRequired,
  theme: themeShape,
  userFrameworkIds: ImmutablePropTypes.list.isRequired,
  userHasPermission: PropTypes.func.isRequired,
  search: ImmutablePropTypes.contains({
    name: PropTypes.string,
  }),
  goBack: PropTypes.func.isRequired,
}

const mapStateToProps = createStructuredSelector({
  policyId: selectPolicyId,
  controlGroups: selectControlGroups,
  deleting: selectDeleting,
  policy: selectPolicy,
  userFrameworkIds: selectUserFrameworkIds,
  saving: selectSaving,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deletePolicy,
      fetchPolicy,
      setPolicy,
      goBack,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const withReducer = injectReducer({ key: 'policyEdit', reducer })

const withSaga = injectSaga({
  key: 'policyEdit',
  saga: sagas,
})

export default compose(
  withReducer,
  withSaga,
  withConnect,
  permissionChecker,
  themeable
)(PolicyEdit)
